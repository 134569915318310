<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref, computed } from 'vue'
import { formatDate, createDate } from '/~/utils/format/date'
import { formatBsb } from '/~/utils/format/string'
import PayeeIcon from '/~/components/app/payee-icon.vue'
import BaseButton from '/~/components/base/button/base-button'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useLocalization } from '/~/composables/localization'
import { useBankAccountForm } from '/~/composables/payment-methods/use-bank-account-form'
import { useScheduledPayments } from '/~/composables/scheduled-payments'
import { useUser } from '/~/composables/user'

export default {
  name: 'pay-form-pay-form-v4',
  components: {
    BaseIcon,
    BaseInput,
    BaseButton,
    BaseCurrencyInput,
    PayeeIcon,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    payee: {
      type: Object,
      required: true,
    },
    column: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { payment, currentFlowType } = useCheckoutReactive()

    const { scheduledPaymentsDateLimit } = useScheduledPayments()
    const { user } = useUser()
    const { formatBusinessNumber, translate } = useLocalization()
    const { accountPresentation } = useBankAccountForm()

    const loading = ref(false)
    const scheduledPaymentDateFrom = computed(() => createDate().utc(true))
    const scheduledPaymentDateTo = computed(() =>
      createDate().utc(true).add(scheduledPaymentsDateLimit.value, 'day')
    )

    return {
      payment,
      user,
      createDate,
      scheduledPaymentDateFrom,
      scheduledPaymentDateTo,
      formatBsb,
      formatBusinessNumber,
      formatDate,
      loading,
      accountPresentation,
      translate,
      currentFlowType,
    }
  },
  data() {
    return {
      reference: '',
      description: '',
      amount: 0,
    }
  },
  computed: {
    isButtonDisabled() {
      return !this.reference || this.amount < 0.01 || !this.description
    },
  },
  created() {
    if (this.isEditing) {
      return
    }

    const reference = this.payee?.reference

    if (reference) {
      this.reference = reference
    }
    if (this.payee.id === this.payment?.payee?.id) {
      if (this.payment.reference) {
        this.reference = this.payment.reference
      }

      this.amount = this.payment.amount
      this.description = this.payment.description
    }
  },
  methods: {
    onCancel() {
      this.$emit('hide')
    },
    async onConfirm() {
      this.loading = true
      if (this.isButtonDisabled) return

      const payload = {
        subtotal: this.amount,
        amount: this.amount,
        reference: this.reference,
        payee: this.payee,
        description: this.description,
        date: null,
      }

      this.currentFlowType = FlowType.payment

      await this.payment.initPayment(payload)

      this.$emit('confirmed', payload)

      this.loading = false
    },
  },
}
</script>

<template>
  <validation-observer v-slot="{ invalid }">
    <div class="mt-6 flex flex-row items-center gap-x-2.5 sm:mt-0">
      <payee-icon :payee="payee" />
      <div>
        <div class="font-bold">
          {{ payee.name }}
        </div>
        <div>
          <template v-if="payee.type === 'bankAccount'">
            <span
              v-if="accountPresentation.bsb"
              class="text-sm text-neutral-600"
            >
              BSB: {{ formatBsb(payee.bsb) }}
            </span>
            <span class="text-sm text-neutral-600">
              Account: {{ payee.accountNumber }}
            </span>
          </template>

          <div v-if="payee.type === 'bpay'" class="divide-x">
            <span class="pr-1 text-sm text-neutral-600">
              Biller: {{ payee.billerCode }}
            </span>
            <span class="pl-1 text-sm text-neutral-600">
              Reference: {{ reference }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mt-5 flex w-full flex-wrap items-start sm:flex-nowrap sm:gap-x-5"
    >
      <div v-if="payee.type === 'bankAccount'" class="w-full">
        <base-input
          v-model="reference"
          :validation="{
            rules: 'required|max:9|alpha_dash_spaces',
            name: 'Recipient Payment Reference',
            mode: 'aggressive',
          }"
          label="Reference"
          required
          name="reference"
          class="!mb-1"
          entry-class="h-11 rounded"
        />
        <span class="inline-block text-sm text-eonx-neutral-800">
          Appears on the payee's statement (max 9 characters)
        </span>
      </div>
      <div class="w-full">
        <validation-provider
          v-slot="{ validate, errors }"
          rules="required|max_value:99999999.99"
          name="Amount"
          mode="eager"
          class="flex w-full justify-between text-inherit"
        >
          <div class="relative mb-1 w-full">
            <label :class="[errors[0] ? 'text-fg-error' : '']">Amount*</label>
            <div
              :class="[
                'flex h-11 items-center rounded border border-solid bg-white',
                { 'border-red-700 text-red-700': errors[0] },
              ]"
            >
              <div class="text-neutrals-500 px-2.5">
                <base-icon svg="v2/box-icons/bx-dollar" :size="20" />
              </div>
              <base-currency-input
                v-model="amount"
                label="Amount"
                :currency="null"
                :error="errors[0]"
                class="w-full"
                required
                allow-null-value
                @input="validate($event)"
              />
            </div>
            <span class="text-sm text-fg-error">
              {{ errors[0] }}
            </span>
          </div>
        </validation-provider>
        <span class="inline-block text-sm text-eonx-neutral-800">
          This amount will be paid to the payee
        </span>
      </div>
    </div>
    <div class="mt-4">
      <base-input
        v-model="description"
        :validation="{
          rules: 'required|max:8|alpha_dash_spaces',
          name: 'Payment description',
          mode: 'aggressive',
        }"
        label="Payment description"
        required
        name="description"
        class="!mb-px mt-1 w-full"
        entry-class="h-11 rounded"
      />

      <span class="mb-5 inline-block text-sm text-eonx-neutral-800">
        Appears on your statement (max 8 characters)
      </span>
    </div>
    <div
      class="-mx-5 flex items-center gap-2 border-t border-eonx-neutral-200 px-5 pt-5 sm:mt-[30px]"
    >
      <div class="w-full">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Other payee payment details',
            cta: 'Cancel',
          }"
          class="w-full"
          look="outlined-color"
          :disabled="loading"
          @click="onCancel"
        >
          Cancel
        </base-button>
      </div>
      <div class="w-full">
        <base-button
          v-analytics:click="{
            pageGroup: 'Make a payment',
            page: 'Other payee payment details',
            cta: 'Confirm',
          }"
          data-testid="confirm-button"
          :disabled="isButtonDisabled || invalid"
          class="w-full"
          :loading="loading"
          @click="onConfirm"
        >
          Confirm
        </base-button>
      </div>
    </div>
  </validation-observer>
</template>
